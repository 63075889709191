$primary-color: #4a71ad;
$primary-font: 'Century Gothic',sans-serif;
$secondary-font: 'Century Gothic-bold',sans-serif;

@font-face {
	font-family: 'Century Gothic';
	src: url('fonts/CenturyGothic.eot');
	src: url('fonts/CenturyGothic.eot?#iefix') format('embedded-opentype'),
		url('fonts/CenturyGothic.woff2') format('woff2'),
		url('fonts/CenturyGothic.woff') format('woff'),
		url('fonts/CenturyGothic.ttf') format('truetype'),
		url('fonts/CenturyGothic.svg#CenturyGothic') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Century Gothic-bold';
	src: url('fonts/CenturyGothic-Bold.eot');
	src: url('fonts/CenturyGothic-Bold.eot?#iefix') format('embedded-opentype'),
		url('fonts/CenturyGothic-Bold.woff2') format('woff2'),
		url('fonts/CenturyGothic-Bold.woff') format('woff'),
		url('fonts/CenturyGothic-Bold.ttf') format('truetype'),
		url('fonts/CenturyGothic-Bold.svg#CenturyGothic-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}


body {
	margin: 0;
	padding: 0;
}

.wrapper-site {
	max-width: 100%;
    margin: 0 auto;
    text-align: center;
}

h1 {
	color: #000;
	font-family: $primary-font;
	line-height: 1.5;
	font-size: 28px;
	text-align: left;

	@media ( max-width:1020px ) {
		font-size: 24px;
	}

	@media ( max-width:600px ) {
		font-size: 19px;
	}
}

/* --------------------------------------------------------------------------------
	HEADER
---------------------------------------------------------------------------------*/
.parallax-window {
    background: transparent;
    background-size:cover;
    display: block;
 	}


    .hero-content {
    	top: 21%;
	    position: absolute;
	    width: 100%;
	    text-align: center;

		@media ( min-width:2550px ) {
			top: 30%;
		}
    }

	.logo {
		width: 755px;

		@media ( max-width:1250px ) {
			width: 600px;
		}

		@media ( max-width:780px ) {
			width: 80%;
		}

	}

	p {
		color: #000;
		font-family: $primary-font;
		margin-bottom: 43px;
	    margin-top: 0%;
	    font-size: 27px;
	    font-weight: 100;


		@media ( max-width:1450px ) {
			font-size: 19px;
		}

		@media ( max-width:630px ) {
			width: 80%;
			margin: 0px auto 40px;
			font-size: 19px;
		}


		@media ( max-width:425px ) {
			width: 57%;
			margin: 13px auto 40px;
			font-size: 17px;
		}

		@media ( min-width:2550px ) {
			font-size: 38px;
		}
	}


/* --------------------------------------------------------------------------------
 	MENU
---------------------------------------------------------------------------------*/


.toogle {
    display: block;
    position: fixed;
    overflow: hidden;
    margin: 0;
    right: 3%;
    top: 3%;
    padding: 0;
    width: 50px;
    height: 50px;
    font-size: 0;
    text-indent: -10000px;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    z-index: 9999;
    transition: background 0.3s;

	&:focus {
	  outline: none;
	}

	span {
		display: block;
		position: absolute;
		top: 24px;
		left: 10px;
		right: 10px;
		height: 3px;
		background: #fff;

		&:after, &:before {
			position: absolute;
			display: block;
			left: 0;
			width: 100%;
			height: 3px;
			background-color: #fff;
			content: "";
  		}

		&:before {
		  	top: -9px;
		}

		&:after {
	  		bottom: -9px;
		}
	}
}



.rot {
  	background-color: $primary-color;
}


nav {
	display: none;
	position: fixed;
    z-index: 200;
    width: 100%;
    height: 100%;
    padding-bottom: 2%;
    padding-top: 5%;
    top: 0;
    text-align: center;
    background: $primary-color;

    @media ( max-width:420px ) {
		padding-top: 27%;
	}

    p {
    	font-size: 9px;
    	margin-bottom: 0;
    	margin-top: 0;
    }

	ul {
	    width: 93%;
	    margin: 5% auto;
	    list-style: none;
	    margin-bottom: 7%;
	    padding-left: 0;


		@media ( max-width:440px ) {
			margin-bottom: 22%;
		}

		li {
			.mobile-reg {
    			display: none;

	    		@media ( max-width:700px ) {
					display: block;
				}
			}
   		}
	}

	#animate-1 {
	    -webkit-animation-delay: 0.1s;
	}

	#animate-2 {				
	    -webkit-animation-delay: 0.2s;    
	}

	#animate-3 {			
	    -webkit-animation-delay: 0.3s;  
	}


	@media ( max-width:1280px ) {
						
		#animate-1, #animate-2, #animate-3 {
			-webkit-animation-delay: none;
		     animation-delay: none;  
		}
	}

	.Menu-item { 
		margin: 0;
		display: block;	
	    margin: 2% 3%;

	    
	}

	.Menu-link {
		color: #fff;
	    display: block;
	    text-decoration: none;
	    z-index: 1;
	    font-size: 36px;
    	font-weight: 100;
	    text-transform: uppercase; 
	    font-family: $primary-font;
	    -o-transition:.5s;
	   -ms-transition:.5s;
	   -moz-transition:.5s;
	   -webkit-transition:.5s;
  		transition:.5s;
  		line-height: 2;

		@media ( max-width:781px ) {
			font-size: 26px;
			line-height: 3;
		}

		@media ( max-width:440px ) {
			font-size: 21px;
			line-height: 1;
			margin-bottom: 16%;

		}

	    &:hover {
	    	color: #B5B4B4;
	    	-o-transition:.5s;
			-ms-transition:.5s;
			-moz-transition:.5s;
			-webkit-transition:.5s;
         	transition:.5s;
	    }
	}

	.information {
		width: 100%;

		&:before {
			content: '';
		    height: 7px;
		    background-color: #1a4584;
		    width: 35%;
    		display: block;
    		margin: 0 auto;

    		@media ( max-width:650px ) {
				width: 80%;		
			}
		}
		
		.box {
			display: inline-block;
			padding: 4%;
			padding-top: 3%;	

			img {
				width: 408px;

				@media ( max-width:758px ) {
					width: 336px;
					margin-top: 9%;
				}	

				@media ( max-width:388px ) {
					width: 80%;

				}	
			}
		}	
	}
} //nav




/* --------------------------------------------------------------------------------
	ABOUT
---------------------------------------------------------------------------------*/
   
 .wrapper-about {
	width: 90%;
	margin: 6% auto;
	margin-bottom:0;

	.inner-about {
		width: 62%;
	    padding-top: 2%;
	    padding-bottom: 9%;
	    margin: 0 auto;
	    text-align: center;

	    @media ( max-width:600px ) {
			width: 90%;
		}

		.about {
			text-align: left;
			width: 46%;
			padding: 5.01%;
			padding-top: 2%;
			display: block;
			position: relative;
			margin-bottom: 7%;

			@media ( max-width:1000px ) {
				width: 94%;
				margin-bottom: 0;
			}
		}  			
	}  
}


 

/* --------------------------------------------------------------------------------
	GALLERY
---------------------------------------------------------------------------------*/


.royalSlider {
	width: 100% !important;
	height: auto;
	min-height: 500px;
	margin: 0 auto;
	position: relative;	

	@media ( max-width:530px ) {
		min-height: 455px;	
	}

	@media ( max-width:390px ) {
		min-height: 348px;
	}

}

	
#productsSliderMobile {
	width: 60% !important;

	@media ( max-width:1200px ) {
		width: 80% !important;
	}

	@media ( max-width:870px ) {
		width: 95% !important;
	}

	.imageHolder {
		position: absolute;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		width: 100%;
		height: 100%;
		top:0;
		left:0;
	}	
}

.royalSlider {
	min-height: 560px !important;
	height: 560px !important;

	@media ( max-width:870px ) {
		min-height: 460px !important;
		height: 460px !important;
	}

	@media ( max-width:485px ) {
		min-height: 360px !important;
		height: 360px !important;
	}

	@media ( max-width:355px ) {
		min-height: 260px !important;
		height: 260px !important;
	}
}

.rsDefault .rsBullets {
	display: none;
}

.rsDefault.rsHor .rsArrowLeft .rsArrowIcn {
	background-color: transparent !important;
	background: url('../img/arrow.svg') !important;
	background-repeat: no-repeat !important;
	width: 87px !important;
    height: 88px !important;
    position: relative !important;
    right: 171px !important;

	-moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";

    @media ( max-width:1200px ) {
		right: 19px !important;
	}

}

.rsDefault.rsHor .rsArrowRight .rsArrowIcn {
	background-color: transparent !important;
	background: url('../img/arrow.svg') !important;
	background-repeat: no-repeat !important;
	margin-left: 0 !important;
	width: 87px !important;
    height: 88px !important;
    position: relative !important;
    right: -149px !important;


	@media ( max-width:1200px ) {
		right: 4px !important;
	}


}


/* --------------------------------------------------------------------------------
	DOWNLOAD
---------------------------------------------------------------------------------*/
 
 
.download {
	margin-top: 11%;

	@media ( max-width:974px ) {
		margin-top: 8%;
	}

	@media ( max-width:375px ) {
		width: 90%;
    	margin: 8% auto;
	}


	img {
		width: 290px;

		@media ( max-width:974px ) {
			display: block;
			margin: 0 auto;
			margin-bottom: 33px;
		}

		@media ( max-width:485px ) {
				width: 70%;
			}
	}

	a {
		color: $primary-color;
		text-transform: uppercase;
		font-family: $primary-font;
		font-size: 25px;
		position: relative;
	    top: -115px;
	    left: 50px;

	    @media ( max-width:974px ) {
			position: relative;
		    top: 0;
		    left: 0;
		}

		@media ( max-width:485px ) {
				font-size: 21px;
			}
	}
}



/* --------------------------------------------------------------------------------
	FOOTER
---------------------------------------------------------------------------------*/

	footer {
		background-color: $primary-color;
		padding-top: 46px;
    	padding-bottom: 30px;
    	margin-top: 6%;
    	width: 100%;

		.inner-footer {
		    overflow: hidden;
		    margin: 0 auto;
		    text-align: center;
		    width: 90%;
		}

		img {
			width: 335px;

			@media ( max-width:1150px ) {
				width: 235px;
			}

			@media ( max-width:319px ) {
				width: 80%;
			}
		}

		span {
			@media ( max-width:290px ) {
				display: block;
			}
		}

		p {
			font-family: $primary-font;
			font-size: 16px;
			color: #fff;
			margin-bottom: 2px;
			text-align: left;

			@media ( max-width:636px ) {
				text-align: center;
			}

			@media ( max-width:290px ) {
				font-size: 15px;
			}
		}

		h2 {
			font-family: $secondary-font;
			color: #fff;
			font-size: 16px;
			text-transform: uppercase;
			text-align: left;

			@media ( max-width:636px ) {
				text-align: center;
			}
		}

		a {
			font-family: $secondary-font;
			color: #fff;
			text-decoration: none;
		}

		.column-1 {
			display: inline-block;
   			width: 480px;

	   		@media ( max-width:1150px ) {
				display: block;
				width: 100%;
				margin: 0 auto
			}
		}

		.column-2 {
			display: inline-block;
    		width: 315px;
    		text-align: left;

    		p:nth-child(5) {
				margin-bottom: 20px;
			}

			@media ( max-width:1150px ) {
				margin-top: 32px;
			}

			@media ( max-width:636px ) {
				width: 100%;
				text-align: center;
				margin-bottom: 52px;
			}
		}

		.column-3 {
			display: inline-block;
        	width: 200px;
        	text-align: left;

        	p:nth-child(4) {
				margin-bottom: 20px;
			}

			@media ( max-width:636px ) {
				width: 100%;
				text-align: center;
			}
		}

	.copyright {
		color: #B2C4D8;
		font-size: 13px;
		text-align: center;
		text-align: center;
	    width: 100%;
    	position: relative;
    	width: 80%;
    	margin: 4% auto;
    	margin-bottom: 0;

    	@media ( max-width:424px ) {
			margin: 40px auto;
		}

    	p {
    		text-align: center;

    	}


		img {
			position: relative;
			top: 5px;
			right: -5px;
			width: 52px;
			border:none !important;

		}
	}
	}


